import { useEffect } from 'react'
import { useAuth0 } from '../react-auth0-wrapper'

const MyLoginPage = ({ theme }) => {
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0()

    useEffect(() => {
        if (loading || isAuthenticated) {
            return
        }

        const fn = async () => {
            await loginWithRedirect({})
        }
        fn()
    }, [])

    return null
}

export default MyLoginPage
