import { Field } from 'react-final-form'
import { Labeled } from 'react-admin'
import PropTypes from 'prop-types'
import React from 'react'

const HiddenInput = ({ name, format }) => {
    return (
        <Labeled label={name}>
            <Field name={name} component="input" type="hidden" format={format}/>
        </Labeled>
    )
}

HiddenInput.propTypes = {
    name: PropTypes.string.isRequired,
    format: PropTypes.func,
}

export default HiddenInput
