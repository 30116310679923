import React from 'react'
import { AppBar } from 'react-admin'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
})


const MyAppBar = props => {
    const classes = useStyles();

    return (
        <AppBar {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
            {process.env.REACT_APP_VERSION}
        </AppBar>
    )
}

export default MyAppBar
