import React from 'react'
import { Datagrid, DeleteButton, Filter, TextField, TextInput } from 'react-admin'
import List from '../components/List'
import { SupervisorAccount } from '@material-ui/icons'

export const ClientsIcon = SupervisorAccount

const ClientFilter = (props) => (
    <Filter {...props}>
        <TextInput source="email" alwaysOn/>
    </Filter>
)

export const ClientsList = (props) => (
    <List {...props} filters={<ClientFilter/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="email"/>
            <TextField source="transactionCount"/>
            <DeleteButton/>
        </Datagrid>
    </List>
)
