import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const JsonTextField = ({ className, source, record = {} }) => {
    return (
        <Typography
            className={className}
            component="pre"
            variant="caption"
        >
            <code>
                {JSON.stringify(record[source], null, 4)}
            </code>
        </Typography>
    )
}

JsonTextField.propTypes = {
    className: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
}

JsonTextField.defaultProps = {
    addLabel: true,
}

export default JsonTextField
