import polyglotI18nProvider from 'ra-i18n-polyglot'
import germanMessages from 'ra-language-german'
import englishMessages from 'ra-language-english'
import { resolveBrowserLocale } from 'react-admin'
import { customMessages as germanCustomMessages, resourceMessages as germanResourceMessages } from './de'
import { customMessages as englishCustomMessages, resourceMessages as englishResourceMessages } from './en'

const messages = {
    de: { ...germanMessages, ...germanResourceMessages, ...germanCustomMessages },
    en: { ...englishMessages, ...englishResourceMessages, ...englishCustomMessages },
}

const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.de,
    resolveBrowserLocale(),
)

export default i18nProvider
