import React from 'react'
import { Datagrid, DateField, Filter, ReferenceField, SelectInput, Show, ShowButton, SimpleShowLayout, TextField } from 'react-admin'
import List from '../components/List'
import ReferenceInput from '../components/ReferenceInput'
import { AttachMoney } from '@material-ui/icons'
import JsonTextField from '../field/JsonField'

export const InstantPaymentNotificationsIcon = AttachMoney

const InstantPaymentNotificationFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="transactionType" choices={[
            { id: 'check-enrollment', name: 'check-enrollment' },
            { id: 'check-payer-response', name: 'check-payer-response' },
            { id: 'authorization-only', name: 'authorization-only' },
            { id: 'purchase', name: 'purchase' },
            { id: 'debit', name: 'debit' },
            { id: 'refund-debit', name: 'refund-debit' },
        ]} alwaysOn/>
        <SelectInput source="transactionState" choices={[
            { id: 'success', name: 'Success' },
            { id: 'failed', name: 'Failed' },
        ]} alwaysOn/>
    </Filter>
)

export const InstantPaymentNotificationsList = (props) => (
    <List {...props} filters={<InstantPaymentNotificationFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid>
            <TextField source="id"/>
            <ReferenceField source="wirecardRequestId" reference="wirecard_requests" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="transactionType"/>
            <TextField source="transactionState"/>
            <DateField source="createdAt" showTime/>
            <ShowButton/>
        </Datagrid>
    </List>
)

export const InstantPaymentNotificationsShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <ReferenceField source="wirecardRequestId" reference="wirecard_requests" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="transactionType"/>
            <TextField source="transactionState"/>
            <JsonTextField source="payload"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
)
