import React from 'react'
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    ReferenceField,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin'
import List from '../components/List'
import ReferenceInput from '../components/ReferenceInput'
import { CompareArrows } from '@material-ui/icons'
import DiscardButton from '../components/DiscardButton'

export const WebHooksIcon = CompareArrows

export const WebHooksList = (props) => (
    <List {...props} sort={{ field: 'restaurantId', order: 'DESC' }}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="url"/>
            <TextField source="method"/>
            <TextField source="comment"/>
            <ReferenceField source="restaurantId" reference="restaurants" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <DateField showTime source="createdAt"/>
            <ShowButton/>
            <EditButton/>
            <DiscardButton/>
        </Datagrid>
    </List>
)

const WebHooksTitle = ({ record }) => {
    return <span>WebHooks {record ? `"${record.name}"` : ''}</span>
}

export const WebHooksEdit = (props) => (
    <Edit title={<WebHooksTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="url" validate={[required()]}/>
            <SelectInput source="method" choices={[
                { id: 'POST', name: 'POST' },
                { id: 'PATCH', name: 'PATCH' },
                { id: 'PUT', name: 'PUT' },
            ]} validate={[required()]}/>
            <TextInput source="rootKey"/>
            <SelectInput source="formatMethod" allowEmpty choices={[
                { id: 'to_json', name: 'to_json' },
                { id: 'to_s', name: 'to_s' },
            ]}/>
            <TextInput source="comment"/>
            <ReferenceInput source="restaurantId" reference="restaurants" validate={[required()]}>
                <SelectInput source="restaurant"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
)

export const WebHooksCreate = (props) => (
    <Create title="Create a WebHooks" {...props}>
        <SimpleForm>
            <TextInput source="url" validate={[required()]}/>
            <SelectInput source="method" choices={[
                { id: 'POST', name: 'POST' },
                { id: 'PATCH', name: 'PATCH' },
                { id: 'PUT', name: 'PUT' },
            ]} validate={[required()]}/>
            <TextInput source="rootKey"/>
            <SelectInput source="formatMethod" allowEmpty choices={[
                { id: 'to_json', name: 'to_json' },
                { id: 'to_s', name: 'to_s' },
            ]}/>
            <TextInput source="comment"/>
            <ReferenceInput source="restaurantId" reference="restaurants" validate={[required()]}>
                <SelectInput source="restaurant"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
)

export const WebHooksShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <ReferenceField source="restaurantId" reference="restaurants" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="url"/>
            <TextField source="method"/>
            <TextField source="comment"/>
            <TextField source="rootKey"/>
            <TextField source="formatMethod"/>
            <DateField showTime source="createdAt"/>
            <DateField showTime source="updatedAt"/>
            <DateField showTime source="discardedAt"/>
        </SimpleShowLayout>
    </Show>
)
