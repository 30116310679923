import React, { useCallback, useEffect, useState } from 'react'
import {
    Create,
    DateTimeInput,
    required,
    Responsive,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useCreate,
    useDataProvider,
    useNotify,
    useRedirect,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { Grid, withStyles } from '@material-ui/core'
import queryString from 'query-string'

const buttonStyles = {
    grid: {
        margin: 'auto',
    },
    small: {
        marginTop: '15px',
        width: '100%',
    },
    rest: {
        marginRight: '15px',
    },
}

const PrinterReportsCreateToolbar = withStyles(buttonStyles)(props => {
    const { classes, ...rest } = props
    return (
        <Responsive
            small={
                <Grid container>
                    <Grid item xs={11} sm={6} className={classes.grid}>
                        <SaveButtonWithReportType reportType="total_sum" redirect={false} label="Gesamtsumme" className={classes.small} {...rest} />
                    </Grid>
                    <Grid item xs={11} sm={6} className={classes.grid}>
                        <SaveButtonWithReportType reportType="transactions" redirect={false} label="Transaktionen" className={classes.small} {...rest} />
                    </Grid>
                </Grid>
            }
            medium={
                <Toolbar {...rest}>
                    <SaveButtonWithReportType reportType="total_sum" redirect={false} label="Gesamtsumme" className={classes.rest}/>
                    <SaveButtonWithReportType reportType="transactions" redirect={false} label="Transaktionen"/>
                </Toolbar>
            }
        />
    )
})

const SaveButtonWithReportType = props => {
    const redirectTo = useRedirect()
    const notify = useNotify()
    const { basePath, redirect, reportType } = props

    const formState = useFormState()
    const [create, { loading }] = useCreate(props.resource, {
        ...formState.values,
        reportType: reportType,
    }, {
        onSuccess: ({ data: newRecord }) => {
            notify('ra.notification.created', 'info', {
                smart_count: 1,
            })
            redirectTo(redirect, basePath, newRecord.id, newRecord)
        },
    })

    const handleClick = useCallback(() => {
        if (!formState.valid) {
            return
        }

        create()
    }, [
        formState.valid,
        formState.values,
        create,
        notify,
        redirectTo,
        redirect,
        basePath,
    ])

    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} disabled={loading}/>
}

export const PrinterReportsCreate = (props) => {
    const restaurantId = queryString.parse(props.location.search).restaurantId

    const [choices, setChoices] = useState([])
    const dataProvider = useDataProvider()

    const start = new Date()
    if (start.getHours() < 6)
        start.setDate(start.getDate() - 1)
    start.setHours(6)
    start.setMinutes(0)
    const end = new Date(start)
    end.setDate(start.getDate() + 1)

    // Get all transactions
    useEffect(() => {
        dataProvider.getPrinterReportTransactions('transactions', {
            sort: { field: 'id', order: 'asc' },
            filter: { restaurantId: restaurantId },
        }).then(({ data }) => {
            setChoices([...new Map(Object.values(data).map(item => [item['waiterNumber'], item])).values()])
        })
    }, [dataProvider, restaurantId])

    return (
        <Create title="Auswertung drucken" {...props}>
            <SimpleForm redirect={false} toolbar={<PrinterReportsCreateToolbar/>} submitOnEnter={false}>
                <TextInput type="hidden" source="restaurantId" defaultValue={restaurantId} label={false} style={{ display: 'none' }}/>
                <TextInput type="hidden" source="reportType" label={false} style={{ display: 'none' }}/>
                <DateTimeInput source="beginsAt" validate={required()} defaultValue={start}/>
                <DateTimeInput source="endsAt" validate={required()} defaultValue={end}/>
                {choices.length > 0 && <SelectInput source="waiterNumber" choices={choices} optionText="waiterNumber" optionValue="waiterNumber" translateChoice={false} allowEmpty
                                                    emptyText="spgo.printerReports.empty"/>}
            </SimpleForm>
        </Create>
    )
}
