import React from 'react'
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'
import { Box, Grid } from '@material-ui/core'
import { useLocale, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment/min/locales'

const WeeklyStatusDash = ({ thisWeekTransaction, lastWeekTransaction, thisWeekGateway, lastWeekGateway }) => {
    moment.locale(useLocale())
    const t = useTranslate()
    const lastWeekNum = moment().isoWeek() - 1
    const thisWeekNum = moment().isoWeek()
    const data = [
        {
            name: t('spgo.dashboard.weekDash.mon'),
            countThisWeekTransaction: 0,
            countLastWeekTransaction: 0,
            countThisWeekGateway: 0,
            countLastWeekGateway: 0,
            amountThisWeek: 0,
            amountLastWeek: 0,
            amountThisGateway: 0,
            amountLastGateway: 0,
        },
        {
            name: t('spgo.dashboard.weekDash.tue'),
            countThisWeekTransaction: 0,
            countLastWeekTransaction: 0,
            countThisWeekGateway: 0,
            countLastWeekGateway: 0,
            amountThisWeek: 0,
            amountLastWeek: 0,
            amountThisGateway: 0,
            amountLastGateway: 0,
        },
        {
            name: t('spgo.dashboard.weekDash.wed'),
            countThisWeekTransaction: 0,
            countLastWeekTransaction: 0,
            countThisWeekGateway: 0,
            countLastWeekGateway: 0,
            amountThisWeek: 0,
            amountLastWeek: 0,
            amountThisGateway: 0,
            amountLastGateway: 0,
        },
        {
            name: t('spgo.dashboard.weekDash.thu'),
            countThisWeekTransaction: 0,
            countLastWeekTransaction: 0,
            countThisWeekGateway: 0,
            countLastWeekGateway: 0,
            amountThisWeek: 0,
            amountLastWeek: 0,
            amountThisGateway: 0,
            amountLastGateway: 0,
        },
        {
            name: t('spgo.dashboard.weekDash.fri'),
            countThisWeekTransaction: 0,
            countLastWeekTransaction: 0,
            countThisWeekGateway: 0,
            countLastWeekGateway: 0,
            amountThisWeek: 0,
            amountLastWeek: 0,
            amountThisGateway: 0,
            amountLastGateway: 0,
        },
        {
            name: t('spgo.dashboard.weekDash.sat'),
            countThisWeekTransaction: 0,
            countLastWeekTransaction: 0,
            countThisWeekGateway: 0,
            countLastWeekGateway: 0,
            amountThisWeek: 0,
            amountLastWeek: 0,
            amountThisGateway: 0,
            amountLastGateway: 0,
        },
        {
            name: t('spgo.dashboard.weekDash.sun'),
            countThisWeekTransaction: 0,
            countLastWeekTransaction: 0,
            countThisWeekGateway: 0,
            countLastWeekGateway: 0,
            amountThisWeek: 0,
            amountLastWeek: 0,
            amountThisGateway: 0,
            amountLastGateway: 0,
        },
    ]

    thisWeekTransaction.forEach(entry => {
        const day = moment(entry.createdAt).weekday()
        data[day].countThisWeekTransaction += 1
        data[day].amountThisWeek += entry.totalAmountCents / 100
    })
    lastWeekTransaction.forEach(entry => {
        const day = moment(entry.createdAt).weekday()
        data[day].countLastWeekTransaction += 1
        data[day].amountLastWeek += entry.totalAmountCents / 100
    })
    thisWeekGateway.forEach(entry => {
        const day = moment(entry.createdAt).weekday()
        data[day].countThisWeekGateway += 1
        data[day].amountThisGateway += entry.totalAmountCents / 100
    })
    lastWeekGateway.forEach(entry => {
        const day = moment(entry.createdAt).weekday()
        data[day].countLastWeekGateway += 1
        data[day].amountLastGateway += entry.totalAmountCents / 100
    })

    const renderColorfulLegend = (value, entry) => <span style={{ color: entry.color }}>{value}</span>

    return (
        <Grid container spacing={3}>
            {/* Weekly Comparison Page */}
            <Grid item xs={12} md={12} lg={12}>
                <Box
                    textAlign={'center'}
                    fontSize={'24px'}
                    fontWeight={'fontWeightBold'}
                    color={'#2196f3'}
                >
                    {`${t('spgo.dashboard.titles.comparison')} (${t('spgo.dashboard.weekDash.week')} ${moment().isoWeek() - 1} - ${t('spgo.dashboard.weekDash.week')} ${moment().isoWeek()})`}
                </Box>
            </Grid>
            {/* Offline Transactions */}
            <Grid item xs={12} md={6} lg={6}>
                <BarChart
                    width={360}
                    height={230}
                    data={data}
                    style={{ margin: 'auto' }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip formatter={(value) => value.toLocaleString('de', { style: 'decimal' })}/>
                    <Legend formatter={renderColorfulLegend}/>
                    <Bar dataKey="countLastWeekTransaction" fill="Maroon"
                         name={`${t('spgo.dashboard.weekDash.week')} ${lastWeekNum} (${t('spgo.dashboard.weekDash.offline')})`}/>
                    <Bar dataKey="countThisWeekTransaction" fill="DarkOrange"
                         name={`${t('spgo.dashboard.weekDash.week')} ${thisWeekNum} (${t('spgo.dashboard.weekDash.offline')})`}/>
                    <Bar dataKey="countLastWeekGateway" fill="Teal"
                         name={`${t('spgo.dashboard.weekDash.week')} ${lastWeekNum} (${t('spgo.dashboard.weekDash.online')})`}/>
                    <Bar dataKey="countThisWeekGateway" fill="Navy"
                         name={`${t('spgo.dashboard.weekDash.week')} ${thisWeekNum} (${t('spgo.dashboard.weekDash.online')})`}/>
                </BarChart>
                <Box
                    textAlign="center"
                    fontSize={'14px'}
                    fontWeight={'fontWeightNormal'}
                    color={'rgba(0, 0, 0, 0.54)'}
                >
                    {t('spgo.dashboard.card.numberSuccess')}
                </Box>
            </Grid>
            {/* Online Transactions */}
            <Grid item xs={12} md={6} lg={6}>
                <LineChart
                    width={375}
                    height={230}
                    data={data}
                    style={{ margin: 'auto' }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip formatter={(value) => value.toLocaleString('de', { style: 'currency', currency: 'EUR' })}/>
                    <Legend formatter={renderColorfulLegend}/>
                    <Line type='linear' dataKey='amountLastWeek' stroke='Maroon' strokeWidth={3}
                          name={`${t('spgo.dashboard.weekDash.week')} ${lastWeekNum} (${t('spgo.dashboard.weekDash.offline')})`}/>
                    <Line type='linear' dataKey='amountThisWeek' stroke='DarkOrange' strokeWidth={3}
                          name={`${t('spgo.dashboard.weekDash.week')} ${thisWeekNum} (${t('spgo.dashboard.weekDash.offline')})`}/>
                    <Line type='monotone' dataKey='amountLastGateway' stroke='TEAL' strokeWidth={3}
                          name={`${t('spgo.dashboard.weekDash.week')} ${lastWeekNum} (${t('spgo.dashboard.weekDash.online')})`}/>
                    <Line type='monotone' dataKey='amountThisGateway' stroke='Navy' strokeWidth={3}
                          name={`${t('spgo.dashboard.weekDash.week')} ${thisWeekNum} (${t('spgo.dashboard.weekDash.online')})`}/>
                </LineChart>
                <Box
                    textAlign="center"
                    fontSize={'14px'}
                    fontWeight={'fontWeightNormal'}
                    color={'rgba(0, 0, 0, 0.54)'}
                >
                    {t('spgo.dashboard.weekDash.title')}
                </Box>
            </Grid>
        </Grid>
    )
}

WeeklyStatusDash.propTypes = {
    thisWeekTransaction: PropTypes.array.isRequired,
    lastWeekTransaction: PropTypes.array.isRequired,
    thisWeekGateway: PropTypes.array.isRequired,
    lastWeekGateway: PropTypes.array.isRequired,
}

export default WeeklyStatusDash
