import React from 'react'
import { ReferenceInput as ReactAdminReferenceInput } from 'react-admin'


const ReferenceInput = ReactAdminReferenceInput

ReferenceInput.defaultProps = {
    allowEmpty: false,
    filter: {},
    filterToQuery: searchText => (searchText ? { q: searchText } : {}),
    perPage: -1,
    sort: { field: 'id', order: 'DESC' },
}

export default ReferenceInput
