import React from 'react'
import { Create, Datagrid, DateField, DeleteButton, Edit, EditButton, Filter, ReferenceField, required, SelectInput, SimpleForm, TextField, TextInput } from 'react-admin'
import List from '../components/List'
import ReferenceInput from '../components/ReferenceInput'
import { Print } from '@material-ui/icons'

export const PrinterConfigurationsIcon = Print

const PrinterConfigurationsFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="restaurantId" reference="restaurants" alwaysOn allowEmpty>
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="printerId" reference="printers" alwaysOn allowEmpty>
            <SelectInput optionText="serialNumber"/>
        </ReferenceInput>
    </Filter>
)

export const PrinterConfigurationsList = (props) => (
    <List {...props} sort={{ field: 'restaurantId', order: 'DESC' }} filters={<PrinterConfigurationsFilter/>}>
        <Datagrid>
            <TextField source="id"/>
            <ReferenceField source="restaurantId" reference="restaurants" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="printerId" reference="printers" link="show">
                <TextField source="serialNumber"/>
            </ReferenceField>
            <TextField source="configType"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
)

export const PrinterConfigurationsEdit = (props) => (
    <Edit title="Edit a PrinterConfiguration" {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <ReferenceInput source="printerId" reference="printers" validate={[required()]}>
                <SelectInput optionText="serialNumber"/>
            </ReferenceInput>
            <ReferenceInput source="restaurantId" reference="restaurants" validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <SelectInput source="configType" choices={[
                { id: 'payment_notifications', name: 'Payment notifications' },
                { id: 'printer_reports', name: 'Printer reports' },
            ]} validate={[required()]}/>
        </SimpleForm>
    </Edit>
)

export const PrinterConfigurationsCreate = (props) => (
    <Create title="Create a PrinterConfiguration" {...props}>
        <SimpleForm>
            <ReferenceInput source="printerId" reference="printers" validate={[required()]}>
                <SelectInput optionText="serialNumber"/>
            </ReferenceInput>
            <ReferenceInput source="restaurantId" reference="restaurants" validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <SelectInput source="configType" choices={[
                { id: 'payment_notifications', name: 'Payment notifications' },
                { id: 'printer_reports', name: 'Printer reports' },
            ]} validate={[required()]}/>
        </SimpleForm>
    </Create>
)
