import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { userLogout } from 'react-admin'
import { MenuItem } from '@material-ui/core'
import { PowerSettingsNew } from '@material-ui/icons'
import { useAuth0 } from '../react-auth0-wrapper'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles({
    menuItem: {
        color: props => props.palette.text.secondary,
    },
    iconMenuPaddingStyle: {
        paddingRight: '1.2em',
    },
    iconPaddingStyle: {
        paddingRight: props => props.spacing.unit,
    },
})

const MyLogoutButton = ({
                            className,
                            locale,
                            redirectTo,
                            translate,
                            userLogout
                            , ...rest
                        }) => {

    const theme = useTheme()
    const classes = useStyles(theme)

    const handleLogout = () => {
        logout({ returnTo: `${window.location.protocol}//${window.location.hostname === 'localhost' ? `${window.location.hostname}:3002` : window.location.hostname}` })
        userLogout(redirectTo)
    }

    const { logout } = useAuth0()

    return (
        <MenuItem
            className={classnames('logout', classes.menuItem, className)}
            onClick={handleLogout}
            {...rest}
        >
        <span className={classes.iconMenuPaddingStyle}>
            <PowerSettingsNew/>
        </span>
            Logout
        </MenuItem>
    )
}

export default compose(connect(undefined, { userLogout }))(MyLogoutButton)
