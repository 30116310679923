import React from 'react'
import { Datagrid, DateField, Edit, EditButton, required, SimpleForm, TextField, TextInput } from 'react-admin'
import List from '../components/List'
import { Settings } from '@material-ui/icons'

export const PaymentMethodsIcon = Settings

export const PaymentMethodsList = (props) => (
    <List {...props} sort={{ field: 'id', order: 'ASC' }}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
            <EditButton/>
        </Datagrid>
    </List>
)

const PaymentMethodsTitle = ({ record }) => {
    return <span>PackageFee {record ? `"${record.name}"` : ''}</span>
}

export const PaymentMethodsEdit = (props) => (
    <Edit title={<PaymentMethodsTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="name" validate={[required()]}/>
        </SimpleForm>
    </Edit>
)
