import React from 'react'
import {
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    EditButton,
    required,
    Responsive,
    Show,
    ShowButton,
    SimpleForm,
    SimpleList,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin'
import List from '../components/List'
import { Business } from '@material-ui/icons'

export const CompanyIcon = Business

export const CompanyList = (props) => (
    <List {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={record => `${record.id} - ${record.name}`}
                    secondaryText={record => `${record.street}, ${record.zipCode} ${record.city}`}
                    tertiaryText={record => new Date(record.createdAt).toLocaleDateString()}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="name"/>
                    <TextField source="street"/>
                    <TextField source="zipCode"/>
                    <TextField source="city"/>
                    <TextField source="country"/>
                    <EditButton/>
                    <ShowButton/>
                    <DeleteButton/>
                </Datagrid>
            }
        />
    </List>
)

const CompanyTitle = ({ record }) => {
    return <span>Company {record ? `"${record.name}"` : ''}</span>
}

export const CompanyEdit = (props) => (
    <Edit title={<CompanyTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="name" validate={[required()]}/>
            <TextInput source="street" validate={[required()]}/>
            <TextInput source="zipCode" validate={[required()]}/>
            <TextInput source="city" validate={[required()]}/>
            <TextInput source="country" validate={[required()]}/>
            <TextInput source="billingMail"/>
        </SimpleForm>
    </Edit>
)

export const CompanyCreate = (props) => (
    <Create title="Create a Company" {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
            <TextInput source="street" validate={[required()]}/>
            <TextInput source="zipCode" validate={[required()]}/>
            <TextInput source="city" validate={[required()]}/>
            <TextInput source="country" validate={[required()]}/>
            <TextInput source="billingMail"/>
        </SimpleForm>
    </Create>
)

export const CompanyShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="street"/>
            <TextField source="zipCode"/>
            <TextField source="city"/>
            <TextField source="country"/>
            <TextField source="billingMail"/>
        </SimpleShowLayout>
    </Show>
)
