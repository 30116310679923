import React from 'react'
import {
    Create,
    Datagrid,
    DateField,
    DeleteButton,
    Edit,
    EditButton,
    ListButton,
    ReferenceField,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    TopToolbar,
} from 'react-admin'
import List from '../components/List'
import ReferenceInput from '../components/ReferenceInput'
import { Restaurant } from '@material-ui/icons'

export const RestaurantIcon = Restaurant

export const RestaurantList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id"/>
            <ReferenceField source="companyId" reference="companies" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="name"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
            <ShowButton/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
)

const RestaurantTitle = ({ record }) => {
    return <span>Restaurant {record ? `"${record.name}"` : ''}</span>
}

export const RestaurantEdit = (props) => (
    <Edit title={<RestaurantTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="name" validate={[required()]}/>
            <TextInput source="street" validate={[required()]}/>
            <TextInput source="zipCode" validate={[required()]}/>
            <TextInput source="city" validate={[required()]}/>
            <TextInput source="email" validate={[required()]}/>
            <TextInput source="phoneNumber"/>
            <TextInput source="facebookPage"/>
            <TextInput source="terminalAuthenticationToken"/>
            <ReferenceInput source="companyId" reference="companies" validate={[required()]}>
                <SelectInput source="company"/>
            </ReferenceInput>
            <SelectInput source="reportType" allowEmpty choices={[
                { id: 'monthly', name: 'spgo.restaurants.reportType.monthly' },
                { id: 'weekly', name: 'spgo.restaurants.reportType.weekly' },
                { id: 'daily', name: 'spgo.restaurants.reportType.daily' },
            ]}/>
            <ReferenceInput source="packageId" reference="packages">
                <SelectInput source="name"/>
            </ReferenceInput>
            <SelectInput source="clientTemplate" allowEmpty choices={[
                { id: 'client_send_receipt_v2', name: 'client_send_receipt_v2' },
                { id: 'client_send_receipt_wirecard', name: 'client_send_receipt_wirecard' },
                { id: 'client_topup', name: 'client_topup' },
            ]}/>
        </SimpleForm>
    </Edit>
)

export const RestaurantCreate = (props) => (
    <Create title="Create a Restaurant" {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
            <TextInput source="street" validate={[required()]}/>
            <TextInput source="zipCode" validate={[required()]}/>
            <TextInput source="city" validate={[required()]}/>
            <TextInput source="email" validate={[required()]}/>
            <TextInput source="phoneNumber"/>
            <TextInput source="facebookPage"/>
            <TextInput source="terminalAuthenticationToken"/>
            <ReferenceInput source="companyId" reference="companies" validate={[required()]}>
                <SelectInput source="company"/>
            </ReferenceInput>
            <SelectInput source="reportType" allowEmpty choices={[
                { id: 'monthly', name: 'spgo.restaurants.reportType.monthly' },
                { id: 'weekly', name: 'spgo.restaurants.reportType.weekly' },
                { id: 'daily', name: 'spgo.restaurants.reportType.daily' },
            ]}/>
            <ReferenceInput source="packageId" reference="packages">
                <SelectInput source="name"/>
            </ReferenceInput>
            <SelectInput source="clientTemplate" allowEmpty choices={[
                { id: 'client_send_receipt_v2', name: 'client_send_receipt_v2' },
                { id: 'client_send_receipt_wirecard', name: 'client_send_receipt_wirecard' },
                { id: 'client_topup', name: 'client_topup' },
            ]}/>
        </SimpleForm>
    </Create>
)

export const RestaurantShow = (props) => (
    <Show actions={<RestaurantShowActions/>} {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <ReferenceField source="companyId" reference="companies" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="name"/>
            <TextField source="street"/>
            <TextField source="phoneNumber"/>
            <TextField source="zipCode"/>
            <TextField source="city"/>
            <TextField source="email"/>
            <TextField source="facebookPage"/>
            <TextField source="terminalAuthenticationToken"/>
            <TextField source="reportType"/>
            <ReferenceField source="packageId" reference="packages" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="clientTemplate"/>
            <TextField source="apiKey"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
)

const RestaurantShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data}/>
        {data && (
            <ListButton basePath={`${basePath}/${data.id}/wpg_business_cases`}/>
        )}
    </TopToolbar>
)
