import React from 'react'
import { Datagrid, DateField, Filter, ReferenceField, SelectInput, Show, ShowButton, SimpleShowLayout, TextField } from 'react-admin'
import List from '../components/List'
import ReferenceInput from '../components/ReferenceInput'
import { AttachMoney } from '@material-ui/icons'
import JsonTextField from '../field/JsonField'

export const WirecardRequestsIcon = AttachMoney

const WirecardFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="status" allowEmpty choices={[
            { id: 'success', name: 'Success' },
            { id: 'pending', name: 'Pending' },
            { id: 'canceled', name: 'Canceled' },
            { id: 'failed', name: 'Failed' },
        ]} alwaysOn/>
        <ReferenceInput source="transactionId" reference="transactions" allowEmpty>
            <SelectInput optionText={choice => `${choice.id} - ${(choice.totalAmountCents / 100 || 0).toLocaleString('de', { style: 'currency', currency: 'EUR' })} (${choice.status})`}/>
        </ReferenceInput>
    </Filter>
)

export const WirecardRequestsList = (props) => (
    <List {...props} filters={<WirecardFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="type"/>
            <TextField source="requestId"/>
            <ReferenceField source="transactionId" reference="transactions" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="responseStatus"/>
            <TextField source="status"/>
            <TextField source="authorizationStatus"/>
            <DateField source="createdAt" showTime/>
            <ReferenceField source="transactionId" reference="transactions" link="show" label="Restaurant">
                <ReferenceField source="restaurantId" reference="restaurants" link="show">
                    <TextField source="name"/>
                </ReferenceField>
            </ReferenceField>
            <ShowButton/>
        </Datagrid>
    </List>
)

export const WirecardRequestsShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="type"/>
            <TextField source="requestId"/>
            <TextField source="wirecardTransactionId"/>
            <ReferenceField source="transactionId" reference="transactions" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <JsonTextField source="requestXml"/>
            <JsonTextField source="response"/>
            <TextField source="responseStatus"/>
            <TextField source="status"/>
            <TextField source="authorizationStatus"/>
            <JsonTextField source="eppresponse"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
)
