import React from 'react'
import { Box, Grid, MenuItem, Select } from '@material-ui/core'
import { useTranslate } from 'react-admin'

const PageSelector = (props) => {
    const { time, text, handler } = props
    const t = useTranslate()

    return (
        <Grid item xs={12} md={12} lg={12}>
            <Box display="flex" mx="auto" textAlign={'center'} fontSize={'24px'} fontWeight={'fontWeightBold'} color={'#2196f3'}
                 style={{ width: 'fit-content', alignItems: 'center' }}>
                {text}
                <Box pl={2}>
                    <Select value={time} onChange={handler} inputProps={{ time: 'time' }}>
                        <MenuItem value={0}>{t('spgo.dashboard.dropdown.day')}</MenuItem>
                        <MenuItem value={1}>{t('spgo.dashboard.dropdown.week')}</MenuItem>
                        <MenuItem value={2}>{t('spgo.dashboard.dropdown.month')}</MenuItem>
                        <MenuItem value={3}>{t('spgo.dashboard.dropdown.total')}</MenuItem>
                    </Select>
                </Box>
            </Box>
        </Grid>
    )
}

export default PageSelector
