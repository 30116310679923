export default {
    login: (params) => {
        console.log('logged in')
        localStorage.setItem('isAuthenticated', '1')
    },
    checkAuth: (params) => {
        console.log('CHECK')

        return Promise.resolve()

        // if (localStorage.getItem('isAuthenticated') === '1') {
        //     return Promise.resolve()
        // } else {
        //     return Promise.reject()
        // }
    },
    logout: (params) => {
        console.log('LOGOUT')
        localStorage.setItem('isAuthenticated', '0')
        return Promise.resolve()
    },
    checkError: (error) => {
        const status = error.status
        console.log(`ERROR: ${status}`)
        if (status === 401 || status === 403) {
            localStorage.setItem('isAuthenticated', '0')
            return Promise.reject()
        }
        return Promise.resolve()
    },
    getPermissions: (params) => {
        // TODO allows us to use roles https://marmelab.com/react-admin/Authorization.html
        console.log('AUTH_GET_PERMISSIONS')

        return Promise.resolve('admin')

        // if (localStorage.getItem('isAuthenticated') === '1') {
        //     return Promise.resolve('admin')
        // } else {
        //     return Promise.reject()
        // }
    },
}
