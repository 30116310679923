import React from 'react'
import { Button, DeleteButton, useNotify, useUpdate } from 'react-admin'

const DiscardButton = (props) => {
    const { record, resource } = props
    const notify = useNotify()
    const [discard, { loading }] = useUpdate(
        resource,
        record.id,
        { ...record, discardedAt: null },
        {},
        {
            undoable: true,
            onSuccess: () => {
                notify('ra.notification.updated', 'info', {smart_count: 1}, true)
            },
            onFailure: () => {
                notify('ra.message.error', 'warning')
            },
        },
    )

    if (!record.discardedAt) {
        return <DeleteButton {...props} label={'spgo.actions.discard'}/>
    } else {
        return <Button color="primary" onClick={discard} disabled={loading} label={'spgo.actions.reactivate'}/>
    }
}

export default DiscardButton
