import React from 'react'
import { Datagrid, DateField, TextField } from 'react-admin'
import List from '../components/List'
import { CardGiftcard } from '@material-ui/icons'

export const VoucherCardsIcon = CardGiftcard

export const VoucherCardsList = (props) => (
    <List {...props} sort={{ field: 'id', order: 'ASC' }}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="loyaltyId"/>
            <TextField source="qrCode"/>
            <TextField source="numberCode"/>
            <TextField source="pin"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
        </Datagrid>
    </List>
)
