import React from 'react'
import { Datagrid, DateField, ReferenceField, Show, ShowButton, SimpleShowLayout, TextField } from 'react-admin'
import List from '../components/List'
import { AttachMoney } from '@material-ui/icons'
import JsonTextField from '../field/JsonField'
import CurrencyField from '../field/CurrencyField'

export const RefundTransactionIcon = AttachMoney

export const RefundTransactionList = (props) => {
    return (
        <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid>
                <TextField source="id"/>
                <ReferenceField source="transactionId" reference="transactions" link="show">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source="status"/>
                <DateField source="updatedAt" showTime/>
                <DateField source="createdAt" showTime/>
                <ShowButton/>
            </Datagrid>
        </List>
    )
}

export const RefundTransactionShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id"/>
                <ReferenceField source="transactionId" reference="transactions" link="show">
                    <TextField source="id"/>
                </ReferenceField>
                <CurrencyField source="amountCents"/>
                <TextField source="requestId"/>
                <TextField source="status"/>
                {/*<TextField source="notifyUrl"/>*/}
                <JsonTextField source="requestBody"/>
                <JsonTextField source="response"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
            </SimpleShowLayout>
        </Show>
    )
}
