import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate, withDataProvider } from 'react-admin'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    isPineGreen: {
        color: '#03787B',
    },
    isCoral: {
        color: '#faa685',
    },
    isSmaller: {
        fontSize: '18px',
    }
})

const StatusDash = (props) => {
    const t = useTranslate()
    const { title, transaction, gateway } = props
    const classes = useStyles()

    const getCard = (entry, index) => {
        return <Box
            textAlign={'center'}
            fontSize={'20px'}
            fontWeight={'fontWeightBold'}
            color={'black'}
        >
            <div className={classes.isSmaller}><span className={classes.isPineGreen}>{entry.value}</span> | <span className={classes.isCoral}>{gateway[index].value}</span></div>
        </Box>
    }
    
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={12} style={{ paddingBottom: 0}}>
                <Box
                    textAlign={'center'}
                    fontSize={'24px'}
                    fontWeight={'fontWeightBold'}
                    color={'#2196f3'}
                >
                    {title}
                </Box>
                <Box textAlign={'center'} fontSize={'18px'} fontWeight={'fontWeightNormal'}
                     dangerouslySetInnerHTML={{ __html: t('spgo.dashboard.card.subtitle', { leftClass: classes.isPineGreen, rightClass: classes.isCoral }) }}/>
            </Grid>
            {
                // key has to be unique, therefore the 0/1 + index
                transaction.map((entry, index) => (
                    <Grid key={index} item xs={6} md={6} lg={6} style={{padding: '16px 0'}}>
                        {getCard(entry, index)}
                        <Box
                            textAlign="center"
                            fontSize={'14px'}
                            fontWeight={'fontWeightNormal'}
                            color={'rgba(0, 0, 0, 0.54)'}
                        >
                            {entry.title}
                        </Box>
                    </Grid>
                ))
            }
        </Grid>
    )
}

StatusDash.propTypes = {
    title: PropTypes.string,
    transaction: PropTypes.array,
    gateway: PropTypes.array,
}

export default withDataProvider(StatusDash)
