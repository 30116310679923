import React from 'react'
import { Admin, fetchUtils, Resource } from 'react-admin'
import { createBrowserHistory as createHistory } from 'history'
import simpleRestProvider from './ra-data-advanced-rest'
import authProvider from './authProvider'
import { useAuth0 } from './react-auth0-wrapper'
import { i18nProvider } from './i18n'
import MyLayout from './MyLayout'
import MyLoginPage from './components/MyLoginPage'
import MyLogoutButton from './components/MyLogoutButton'
import Dashboard from './Dashboard'
import customRoutes from './customRoutes'
import { RestaurantCreate, RestaurantEdit, RestaurantIcon, RestaurantList, RestaurantShow } from './resources/Restaurants'
import { CompanyCreate, CompanyEdit, CompanyIcon, CompanyList, CompanyShow } from './resources/Companies'
import { TransactionsIcon, TransactionsList, TransactionsShow } from './resources/Transactions'
import { WirecardRequestsIcon, WirecardRequestsList, WirecardRequestsShow } from './resources/WirecardRequests'
import { ClientsIcon, ClientsList } from './resources/Clients'
import { LocalesEdit, LocalesIcon, LocalesList, LocalesShow } from './resources/Locales'
import { ConfigurationsCreate, ConfigurationsEdit, ConfigurationsIcon, ConfigurationsList } from './resources/Configurations'
import { WpgBusinessCasesCreate, WpgBusinessCasesEdit, WpgBusinessCasesIcon, WpgBusinessCasesList, WpgBusinessCasesShow } from './resources/WpgBusinessCases'
import { WebHooksCreate, WebHooksEdit, WebHooksIcon, WebHooksList, WebHooksShow } from './resources/WebHooks'
import { PrinterReportsCreate } from './resources/PrinterReports'
import { PrintersCreate, PrintersEdit, PrintersIcon, PrintersList } from './resources/Printers'
import { PaymentGatewayTransactionIcon, PaymentGatewayTransactionList, PaymentGatewayTransactionShow } from './resources/PaymentGatewayTransactions'
// import { RevenuesIcon, RevenuesList } from './resources/Revenues'
import { InstantPaymentNotificationsIcon, InstantPaymentNotificationsList, InstantPaymentNotificationsShow } from './resources/InstantPaymentNotifications'
import { CreditCardInfosIcon, CreditCardInfosList, CreditCardInfosShow } from './resources/CreditCardInfos'
import { PrinterConfigurationsCreate, PrinterConfigurationsEdit, PrinterConfigurationsIcon, PrinterConfigurationsList } from './resources/PrinterConfigurations'
import { PackagesCreate, PackagesEdit, PackagesIcon, PackagesList } from './resources/Packages'
import { PackageFeesCreate, PackageFeesEdit, PackageFeesIcon, PackageFeesList, PackageFeesShow } from './resources/PackageFees'
import { PaymentMethodsEdit, PaymentMethodsIcon, PaymentMethodsList } from './resources/PaymentMethods'
import { RefundTransactionIcon, RefundTransactionList, RefundTransactionShow } from './resources/RefundTransactions'
import { VoucherRequestsIcon, VoucherRequestsList, VoucherRequestsShow } from './resources/VoucherRequests'
import { VoucherCardsIcon, VoucherCardsList } from './resources/VoucherCards'
import { VoucherBalancesIcon, VoucherBalancesList } from './resources/VoucherBalances'

const history = createHistory()

function App () {
    const { loading, getTokenSilently } = useAuth0()

    const httpClient = async (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' })
        }

        options.headers.set('Key-Inflection', 'camel')

        if (options.resource && options.resource === 'printer_reports') {
            return fetchUtils.fetchJson(url, options)
        }

        try {
            const token = await getTokenSilently()
            options.user = {
                authenticated: true,
                token: `Bearer token=${token}`,
            }
            return fetchUtils.fetchJson(url, options)
        } catch (error) {
            return Promise.reject({ message: error.error_description, status: 401 })
        }
    }

    if (loading) {
        return (
            <div>Loading...</div>
        )
    }

    return (
        <Admin history={history}
               layout={MyLayout}
               customRoutes={customRoutes}
               authProvider={authProvider}
               loginPage={MyLoginPage}
               logoutButton={MyLogoutButton}
               dataProvider={simpleRestProvider(`${process.env.REACT_APP_BASE_URL}/admin/v1`, httpClient)}
               dashboard={Dashboard}
               i18nProvider={i18nProvider}
        >
            <Resource name={'companies'} list={CompanyList} edit={CompanyEdit} create={CompanyCreate} icon={CompanyIcon} show={CompanyShow}/>
            <Resource name={'restaurants'} list={RestaurantList} edit={RestaurantEdit} create={RestaurantCreate} icon={RestaurantIcon} show={RestaurantShow}/>
            <Resource name={'transactions'} list={TransactionsList} icon={TransactionsIcon} show={TransactionsShow}/>
            <Resource name={'wirecard_requests'} list={WirecardRequestsList} icon={WirecardRequestsIcon} show={WirecardRequestsShow}/>
            <Resource name={'instant_payment_notifications'} list={InstantPaymentNotificationsList} show={InstantPaymentNotificationsShow} icon={InstantPaymentNotificationsIcon}/>
            <Resource name={'credit_card_infos'} list={CreditCardInfosList} show={CreditCardInfosShow} icon={CreditCardInfosIcon}/>
            <Resource name={'payment_gateway_transactions'} list={PaymentGatewayTransactionList} icon={PaymentGatewayTransactionIcon} show={PaymentGatewayTransactionShow}/>
            <Resource name={'refund_transactions'} list={RefundTransactionList} icon={RefundTransactionIcon} show={RefundTransactionShow}/>
            <Resource name={'voucher_requests'} list={VoucherRequestsList} icon={VoucherRequestsIcon} show={VoucherRequestsShow}/>
            <Resource name={'voucher_cards'} list={VoucherCardsList} icon={VoucherCardsIcon}/>
            <Resource name={'clients'} list={ClientsList} icon={ClientsIcon}/>
            <Resource name={'voucher_balances'} list={VoucherBalancesList} icon={VoucherBalancesIcon}/>
            <Resource name={'locales'} list={LocalesList} icon={LocalesIcon} edit={LocalesEdit} show={LocalesShow}/>
            <Resource name={'configurations'} list={ConfigurationsList} icon={ConfigurationsIcon} create={ConfigurationsCreate} edit={ConfigurationsEdit}/>
            <Resource name={'wpg_business_cases'} list={WpgBusinessCasesList} icon={WpgBusinessCasesIcon} create={WpgBusinessCasesCreate} edit={WpgBusinessCasesEdit}
                      show={WpgBusinessCasesShow}/>
            <Resource name={'printers'} list={PrintersList} icon={PrintersIcon} create={PrintersCreate} edit={PrintersEdit}/>
            <Resource name={'printer_configurations'} list={PrinterConfigurationsList} icon={PrinterConfigurationsIcon} edit={PrinterConfigurationsEdit}
                      create={PrinterConfigurationsCreate}/>
            <Resource name={'web_hooks'} list={WebHooksList} icon={WebHooksIcon} create={WebHooksCreate} edit={WebHooksEdit} show={WebHooksShow}/>
            <Resource name={'packages'} list={PackagesList} icon={PackagesIcon} create={PackagesCreate} edit={PackagesEdit}/>
            <Resource name={'package_fees'} list={PackageFeesList} icon={PackageFeesIcon} create={PackageFeesCreate} edit={PackageFeesEdit} show={PackageFeesShow}/>
            {/*<Resource name={'transactions.revenue'} list={RevenuesList} icon={RevenuesIcon}/>*/}
            <Resource name={'printer_reports'} create={PrinterReportsCreate}/>
            <Resource name={'payment_methods'} list={PaymentMethodsList} edit={PaymentMethodsEdit} icon={PaymentMethodsIcon}/>
        </Admin>
    )
}

export default App
