import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'

// Formats the integer cents from the backend to floating number for formatting to currency
const CurrencyField = ({ className, record, source, locales, ...rest }) => {
    if (!record) return null

    const value = get(record, source)
    if (value == null) return null

    return (
        <Typography variant="body2" component="span" className={className}>
            {(value / 100).toLocaleString(locales, { style: 'currency', currency: 'EUR' })}
        </Typography>
    )
}

CurrencyField.propTypes = {
    className: PropTypes.string,
    locales: PropTypes.string,
    addLabel: PropTypes.bool,
    textAlign: PropTypes.string,
}

CurrencyField.defaultProps = {
    locales: 'de',
    addLabel: true,
    textAlign: 'right',
}

export default CurrencyField
