import React from 'react'
import { Datagrid, DateField, DeleteButton, Edit, EditButton, required, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput } from 'react-admin'
import List from '../components/List'
import JsonTextField from '../field/JsonField'
import { LibraryBooks } from '@material-ui/icons'
import JsonEditorInput from '../input/JsonEditorInput'
import { useForm, useFormState } from 'react-final-form'

export const LocalesIcon = LibraryBooks

const LocalesTranslationPanel = ({ id, record, resource }) => (
    <JsonTextField source="translation" record={record} resource={resource}/>
)

export const LocalesList = (props) => (
    <List {...props} perPage={10}>
        <Datagrid expand={<LocalesTranslationPanel/>}>
            <TextField source="id"/>
            <TextField source="language"/>
            <DateField source="updatedAt" locales='de'/>
            <EditButton/>
            <ShowButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
)

const LocalesTitle = ({ record }) => {
    return <span>Locales {record ? `"${record.name}"` : ''}</span>
}

const LocalesOrigin = () => {
    const form = useForm()
    const formState = useFormState()

    return (
        <JsonEditorInput name="translation" json={formState.values.translation} options={{
            mode: 'tree',
            search: true,
            mainMenuBar: true,
            navigationBar: false,
            sortObjectKeys: false,
            name: 'translation',
            onChangeJSON: function (json) {
                form.change('translation', json)
            },
        }}/>
    )
}

export const LocalesEdit = (props) => (
    <Edit title={<LocalesTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="language" validate={[required()]}/>
            <LocalesOrigin/>
        </SimpleForm>
    </Edit>
)

export const LocalesShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="language"/>
            <JsonTextField source="translation"/>
        </SimpleShowLayout>
    </Show>
)
