import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import JSONEditor from 'jsoneditor'
import HiddenInput from '../input/HiddenInput'

import 'jsoneditor/dist/jsoneditor.css'

const JsonEditorInput = ({ name, json, options }) => {
    const [jsonEditor, setJsonEditor] = useState(null)
    const [container, setContainer] = useState('')
    useEffect(() => {
        if (!container) return

        setJsonEditor(new JSONEditor(container, options))
    }, [container])

    useEffect(() => {
        if (!jsonEditor) return

        jsonEditor.set(json)
    }, [jsonEditor])

    return (
        <div>
            <HiddenInput name={name}/>
            <div ref={elem => setContainer(elem)}/>
        </div>
    )
}

JsonEditorInput.propTypes = {
    name: PropTypes.string.isRequired,
    json: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
}

export default JsonEditorInput
