import React from 'react'
import { List as ReactAdminList, Pagination as ReactAdminPagination } from 'react-admin'

const Pagination = props => <ReactAdminPagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />

const List = ReactAdminList

List.defaultProps = {
    bulkActionButtons: false,
    pagination: <Pagination/>,
    perPage: 25,
}

export default List
