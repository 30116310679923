import React from 'react'
import { Datagrid, DateField, ReferenceField, Show, ShowButton, SimpleShowLayout, TextField } from 'react-admin'
import List from '../components/List'
import { AttachMoney } from '@material-ui/icons'
import JsonTextField from '../field/JsonField'

export const PaymentGatewayTransactionIcon = AttachMoney

export const PaymentGatewayTransactionList = (props) => {
    return (
        <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid>
                <TextField source="id"/>
                <ReferenceField source="transactionId" reference="transactions" link="show">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source="invoice"/>
                <DateField source="updatedAt" showTime/>
                <DateField source="createdAt" showTime/>
                <ShowButton/>
            </Datagrid>
        </List>
    )
}

export const PaymentGatewayTransactionShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id"/>
                <ReferenceField source="transactionId" reference="transactions" link="show">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source="invoice"/>
                <TextField source="returnUrl"/>
                <TextField source="cancelReturnUrl"/>
                <TextField source="notifyUrl"/>
                <JsonTextField source="custom"/>
                <JsonTextField source="billing"/>
                <JsonTextField source="shopResponse"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
            </SimpleShowLayout>
        </Show>
    )
}
