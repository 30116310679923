import React from 'react'
import { Create, Datagrid, DeleteButton, Edit, EditButton, required, SimpleForm, TextField, TextInput } from 'react-admin'
import List from '../components/List'
import { AccountBalance } from '@material-ui/icons'

export const PackagesIcon = AccountBalance

export const PackagesList = (props) => (
    <List {...props} sort={{ field: 'restaurantId', order: 'DESC' }}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
)

const PackagesTitle = ({ record }) => {
    return <span>Package {record ? `"${record.name}"` : ''}</span>
}

export const PackagesEdit = (props) => (
    <Edit title={<PackagesTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="name" validate={[required()]}/>
        </SimpleForm>
    </Edit>
)

export const PackagesCreate = (props) => (
    <Create title="Create a Package" {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
        </SimpleForm>
    </Create>
)
