import React, { useEffect } from 'react'
import { Box, Card, CardContent, Grid, Paper } from '@material-ui/core'
import { Title, useGetList, useLocale, useQuery, useTranslate } from 'react-admin'
import StatusDash from './components/StatusDash'
import WeeklyStatusDash from './components/WeeklyStatusDash'
import PaymentStatusDash from './components/PaymentStatusDash'
import moment from 'moment'

const Dashboard = ({ permissions }) => {
    moment.locale(useLocale())
    const t = useTranslate()

    useEffect(() => {
        function clickButton () {
            // Wait for AppBar to load properly
            setTimeout(() => {
                // Get button by aria label (no other unique property available)
                const button = document.querySelector('[aria-label="open drawer"]')
                button.click()
            }, 1000)
        }

        clickButton()
        return function cleanup () {
            clickButton()
        }
    }, [])

    // API calls
    const paymentGatewayTransactions = useGetList('payment_gateway_transactions', { page: 1, perPage: -1 }, { field: 'id', order: 'ASC' }, {})
    const transactions = useQuery({
        type: 'getList',
        resource: 'transactions',
        payload: {
            pagination: { page: 1, perPage: -1 },
            sort: { field: 'id', order: 'ASC' },
            filter: {
                select: ['id',
                    'status',
                    'enteredAmountCents',
                    'totalAmountCents',
                    'tipsAmountCents',
                    'tipsPercentage',
                    'paymentMethodId',
                    'restaurantId',
                    'createdAt',
                    'updatedAt',
                ],
            },
        },
    })
    if (transactions.loading || paymentGatewayTransactions.loading) return <p>{t('spgo.load')}</p>
    if (transactions.error || paymentGatewayTransactions.error) return <p>{t('spgo.error')}</p>
    if (!transactions.data && !paymentGatewayTransactions.data) return null

    const gatewayValues = Object.values(paymentGatewayTransactions.data).map(entry => entry.transactionId)
    const values = Object.values(transactions.data).filter(entry => entry.restaurantId !== 1)

    const [transaction, gateway] = values.reduce(
        ([t, g], entry) => {
            if (!gatewayValues.includes(entry.id))
                return [[...t, entry], g]
            else
                return [t, [...g, entry]]
        },
        [[], []],
    )

    // Check if authenticated
    if (permissions !== 'admin') {
        return (
            <Card>
                <Title title="Dashboard"/>
                <CardContent>
                    {t('spgo.dashboard.unauthorized')}
                </CardContent>
            </Card>
        )
    }

    const thisMonthTransaction = transaction.filter(entry => moment().isSame(new Date(entry.createdAt), 'month'))
    const thisMonthGateway = gateway.filter(entry => moment().isSame(new Date(entry.createdAt), 'month'))
    const thisWeekTransaction = transaction.filter(entry => moment().isSame(new Date(entry.createdAt), 'isoWeek'))
    const thisWeekGateway = gateway.filter(entry => moment().isSame(new Date(entry.createdAt), 'isoWeek'))
    const lastWeekTransaction = transaction.filter(entry => moment().subtract(1, 'week').isSame(new Date(entry.createdAt), 'isoWeek'))
    const lastWeekGateway = gateway.filter(entry => moment().subtract(1, 'week').isSame(new Date(entry.createdAt), 'isoWeek'))
    const thisDayTransaction = thisWeekTransaction.filter(entry => moment().isSame(new Date(entry.createdAt), 'day'))
    const thisDayGateway = thisWeekGateway.filter(entry => moment().isSame(new Date(entry.createdAt), 'day'))
    const statusData = [
        {
            title: moment().format('dddd, DD MMM YYYY'),
            data: thisDayTransaction,
            gateway: thisDayGateway,
        },
        {
            title: `${t('spgo.dashboard.weekDash.week')}  ${moment().isoWeek()} (${moment().isoWeekday(1).format('DD.MMM')} - ${moment().isoWeekday(7).format('DD.MMM')})`,
            data: thisWeekTransaction,
            gateway: thisWeekGateway,
        },
        {
            title: moment().format('MMMM YYYY'),
            data: thisMonthTransaction,
            gateway: thisMonthGateway,
        },
        {
            title: t('spgo.dashboard.dropdown.total'),
            data: transaction,
            gateway: gateway,
        },
    ]

    return (
        <Card>
            <Title title="stampayGo Dashboard"/>
            <CardContent>
                <Grid container spacing={3}>
                    { /* Refresh time */}
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper style={{ padding: '8px' }}>
                            <Box
                                textAlign={'center'}
                                fontSize={'24px'}
                                fontWeight={'fontWeightBold'}
                                color={'#2196f3'}
                            >
                                {`${t('spgo.refreshTitle')} ${moment().format('DD.MM.YY - HH:mm')}`}
                            </Box>
                        </Paper>
                    </Grid>
                    {/* Daily */}
                    {
                        statusData.map((entry, index) => {
                            const transactionSuccess = entry.data.filter(val => val.status === 'success')
                            const gatewaySuccess = entry.gateway.filter(val => val.status === 'success')
                            const transactionHelperValue = transactionSuccess.reduce((acc, val) => acc + val.totalAmountCents, 0) / 100
                            const gatewayHelperValue = gatewaySuccess.reduce((acc, val) => acc + val.totalAmountCents, 0) / 100

                            const statusTransaction = [
                                {
                                    value: entry.data.length,
                                    title: t('spgo.dashboard.card.numberTransactions'),
                                },
                                {
                                    value: transactionHelperValue.toLocaleString('de', {
                                        style: 'currency',
                                        currency: 'EUR',
                                    }),
                                    title: t('spgo.dashboard.card.transactionVolume'),
                                },
                                { value: transactionSuccess.length, title: t('spgo.dashboard.card.numberSuccess') },
                                {
                                    value: ((transactionHelperValue / transactionSuccess.length) || 0).toLocaleString('de', {
                                        style: 'currency',
                                        currency: 'EUR',
                                    }),
                                    title: t('spgo.dashboard.card.averageVolume'),
                                },
                            ]
                            const statusGateway = [
                                {
                                    value: entry.gateway.length,
                                },
                                {
                                    value: gatewayHelperValue.toLocaleString('de', {
                                        style: 'currency',
                                        currency: 'EUR',
                                    }),
                                },
                                {
                                    value: gatewaySuccess.length,
                                },
                                {
                                    value: ((gatewayHelperValue / gatewaySuccess.length) || 0).toLocaleString('de', {
                                        style: 'currency',
                                        currency: 'EUR',
                                    }),
                                },
                            ]

                            return (
                                <Grid key={`0${index}`} item xs={12} md={6} lg={6}>
                                    <Paper style={{ padding: '16px' }}>
                                        <StatusDash
                                            title={entry.title}
                                            transaction={statusTransaction}
                                            gateway={statusGateway}
                                        />
                                    </Paper>
                                </Grid>
                            )
                        })
                    }
                    {/* Weekly Graphs */}
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper style={{ padding: '16px' }}>
                            <WeeklyStatusDash
                                thisWeekTransaction={thisWeekTransaction.filter(val => val.status === 'success')}
                                lastWeekTransaction={lastWeekTransaction.filter(val => val.status === 'success')}
                                thisWeekGateway={thisWeekGateway.filter(val => val.status === 'success')}
                                lastWeekGateway={lastWeekGateway.filter(val => val.status === 'success')}
                            />
                        </Paper>
                    </Grid>
                    {/* Payment Graphs */}
                    <PaymentStatusDash
                        transaction={[thisDayTransaction, thisWeekTransaction, thisMonthTransaction, transaction]}
                        gateway={[thisDayGateway, thisWeekGateway, thisMonthGateway, gateway]
                        }
                    />
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Dashboard
