import { stringify } from 'query-string'
import { mapKeys, snakeCase } from 'lodash'
import { fetchUtils } from 'react-admin'

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => ({
    getList: (resource, params) => {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const select = params.filter.select?.map((v) => {return snakeCase(v)})
        delete params.filter.select
        const filter = mapKeys(params.filter, (value, key) => {return snakeCase(key)})
        const query = {
            sort: JSON.stringify([snakeCase(field), order]),
            items: perPage,
            page: page,
            filter: JSON.stringify(Object.entries(filter).reduce((a, [k, v]) => (v === '' ? a : { ...a, [k]: v }), {})), // Removes { foo: '' }
            select: JSON.stringify(select),
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`

        return httpClient(url, { resource: resource }).then(({ headers, json }) => {
            if (!headers.has('Total-Count')) {
                throw new Error(
                    'The Total-Count header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Total-Count in the Access-Control-Expose-Headers header?',
                )
            }
            return {
                data: json,
                total: parseInt(headers.get('Total-Count'), 10),
            }
        })
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, { resource: resource }).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params, options) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
            items: params.ids.length,
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`
        return httpClient(url).then(({ json }) => ({ data: json }))
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const filter = mapKeys(params.filter, (value, key) => {return snakeCase(key)})
        const query = {
            sort: JSON.stringify([snakeCase(field), order]),
            items: perPage,
            page: page,
            filter: JSON.stringify({
                ...Object.entries(filter).reduce((a, [k, v]) => (v === '' ? a : { ...a, [k]: v }), {}),
                [snakeCase(params.target)]: params.id,
            }),
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has('Total-Count')) {
                throw new Error(
                    'The Total-Count header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Total-Count in the Access-Control-Expose-Headers header?',
                )
            }
            return {
                data: json,
                total: parseInt(headers.get('Total-Count'), 10),
            }
        })
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
            resource: resource,
        }).then(({ json }) => ({ data: json })),

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                    resource: resource,
                }),
            ),
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
            resource: resource,
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
            resource: resource,
        }).then(({ json }) => ({ data: json })),

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                }),
            ),
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

    // Same as get list but without authentication
    getPrinterReportTransactions: (resource, params) => {
        const { field, order } = params.sort
        const filter = mapKeys(params.filter, (value, key) => {return snakeCase(key)})
        const query = {
            sort: JSON.stringify([snakeCase(field), order]),
            filter: JSON.stringify(Object.entries(filter).reduce((a, [k, v]) => (v === '' ? a : { ...a, [k]: v }), {})), // Removes { foo: '' }
        }
        const url = `${apiUrl}/printer_reports/${resource}?${stringify(query)}`

        return httpClient(url, { resource: 'printer_reports' }).then(({ headers, json }) => {
            return {
                data: json,
            }
        })
    },
})
