export const resourceMessages = {
    resources: {
        clients: {
            name: 'Kunde |||| Kunden',
            fields: {
                email: 'E-Mail',
                transactionCount: 'Anzahl Transaktionen',
            },
        },
        companies: {
            name: 'Firma |||| Firmen',
            fields: {
                name: 'Name',
                street: 'Straße',
                zipCode: 'Postleitzahl',
                city: 'Stadt',
                country: 'Land',
            },
        },
        configurations: {
            name: 'Konfiguration |||| Konfigurationen',
            fields: {
                configType: 'Konfiguration',
                value: 'Wert',
                type: 'Art',
            },
        },
        credit_card_infos: {
            name: 'KK-Info |||| KK-Infos',
            fields: {
                authorizationCode: 'Authorization Code',
                cardType: 'Kartentyp',
                createdAt: 'Erstellt am',
                expirationMonth: 'Ablaufmonat',
                expirationYear: 'Ablaufjahr',
                firstName: 'Vorname',
                lastName: 'Nachname',
                maskedAccountNumber: 'Maskierte Kartennummer',
                pspFormResponse: 'Antwort vom Brigde-Form (JSON)',
                tokenId: 'Token',
                updatedAt: 'Aktualisiert am',
                wirecardRequestId: 'Wirecard Vorgang',
            },
        },
        instant_payment_notifications: {
            name: 'IPN |||| IPNs',
            fields: {
                createdAt: 'Erstellt am',
                payload: 'Inhalt',
                transactionType: 'Art der Transaktion',
                transactionState: 'Status der Transaktion',
                updatedAt: 'Aktualisiert am',
                wirecardRequestId: 'Wirecard Vorgang',
            },
        },
        locales: {
            name: 'Übersetzung |||| Übersetzungen',
            fields: {
                language: 'Sprache',
                translation: 'Übersetzung (JSON)',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
                discardedAt: 'Deaktiviert am',
            },
        },
        packages: {
            name: 'Paket |||| Pakete',
            fields: {
                name: 'Name',
            },
        },
        package_fees: {
            name: 'Paketkosten',
            fields: {
                paymentMethodId: 'Bezahlmethode',
                packageId: 'Paket',
                shared: 'Variable Kosten',
                fixed: 'Festkosten',
                buyRateFixed: 'Buy Rate (Fest)',
                sellRateFixed: 'Sell Rate (Fest)',
                buyRateShared: 'Buy Rate (Variabel)',
                sellRateShared: 'Sell Rate (Variabel)',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
            },
        },
        payment_gateway_transactions: {
            name: 'Pay. Gatew. Transaktion |||| Pay. Gatew. Transaktionen',
            fields: {
                transactionId: 'Transaktion',
                invoice: 'Rechnungsnr.',
                returnUrl: 'Return URL (Erfolgreich)',
                cancelReturnUrl: 'Return URL (Abbruch/Fehlschlag)',
                notifyUrl: 'Web hook URL für Instant Payment Notification',
                custom: 'Custom Parameter zur identifizifierung der Transaktion',
                shopResponse: 'Antwort vom Shop (IPN)',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
            },
        },
        payment_methods: {
            name: 'Bezahlmethode |||| Bezahlmethoden',
            fields: {
                name: 'Name',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
            },
        },
        printers: {
            name: 'Drucker',
            fields: {
                restaurantId: 'Restaurant',
                serialNumber: 'Seriennummer',
                statusCode: 'Status code',
                comment: 'Kommentar',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
            },
        },
        printer_configurations: {
            name: 'Drucker Konfiguration |||| Drucker Konfigurationen',
            fields: {
                printerId: 'Drucker',
                restaurantId: 'Restaurant',
                configType: 'Typ',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
            },
        },
        printer_reports: {
            name: 'Kassenzettel-Report |||| Kassenzettel-Reports',
            fields: {
                beginsAt: 'Anfang',
                endsAt: 'Ende',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
                waiterNumber: 'Kellnernummer',
            },
        },
        refund_transactions: {
            name: 'Storno-Transaktion |||| Storno-Transaktionen',
            fields: {
                transactionId: 'Transaktion',
                amountCents: 'Betrag (Kann von der Gesamtsumme abweichen, Teilstorno)',
                requestId: 'Transaktions-Id',
                status: 'Status',
                requestBody: 'Body der Anfrage',
                response: 'Antwort vom PSP',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
            },
        },
        restaurants: {
            name: 'Restaurant |||| Restaurants',
            fields: {
                companyId: 'Firma',
                name: 'Name',
                street: 'Straße',
                phoneNumber: 'Telefonnummer',
                zipCode: 'Postleitzahl',
                city: 'Stadt',
                email: 'E-Mail Adresse',
                facebookPage: 'Facebook-Seite',
                createdAt: 'Erstellt am',
                packageId: 'Paket',
                reportType: 'Art des Reportings',
                terminalAuthenticationToken: 'Auth-Token für das Loyalty-System (terminalAuthenticationToken)',
                updatedAt: 'Aktualisiert am',
                discardedAt: 'Deaktiviert am',
            },
        },
        transactions: {
            name: 'Transaktion |||| Transaktionen',
            revenue: {
                name: 'Umsatz |||| Umsätze',
            },
            fields: {
                paymentMethodId: 'Bezahlmethode',
                status: 'Status',
                totalAmountCents: 'Gesamt-Rechnungsbetrag',
                parsedAmountCents: 'Gescannter Betrag',
                enteredAmountCents: 'Eingegebener Betrag',
                tipsPercentage: 'Trinkgeld in Prozent',
                tipsAmountCents: 'Trinkgeld Betrag',
                clientReceiptSendingStatus: 'Sendestatus',
                receiptPhotoUrl: 'Kassenzettel',
                remainingAmountCents: 'Zu zahlender Betrag',
                waiterNumber: 'Kellner',
                externalTransactionNumber: 'TransaktionsID des Partners',
                clientId: 'Kunde',
                ocrResponse: 'Ergebnis des Scanvorgangs',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
                revenueAmountCents: 'Umsatz',
                voucherAmountCents: 'Mit Guthaben gezahlt',
            },
        },
        voucher_balances: {
            name: 'Gutscheinguthaben',
            fields: {
                voucherCardId: 'Gutscheinkarte',
                restaurantId: 'Restaurant',
                amountCents: 'Guthaben',
                amountUpdatedAt: 'Zuletzt abgerufen vom Loyalty-System',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
            },
        },
        voucher_cards: {
            name: 'Gutscheinkarte |||| Gutscheinkarten',
            fields: {
                loyaltyId: 'ID im Loyalty-System',
                qrCode: 'Inhalt des Qr-Codes',
                numberCode: 'Kartennummer',
                pin: 'Security-Code (PIN)',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
            },
        },
        voucher_requests: {
            name: 'Gutscheinanfrage |||| Gutscheinanfragen',
            fields: {
                transactionId: 'Transaktion',
                voucherCardId: 'Gutscheinkarte',
                status: 'Status',
                pin: 'Eingegebener Security-Code (PIN)',
                requestType: 'Typ',
                response: 'Server-Antwort',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
            },
        },
        web_hooks: {
            name: 'Web Hook |||| Web Hooks',
            fields: {
                url: 'Adresse',
                method: 'Methode',
                rootKey: 'Root key für Daten',
                formatMethod: 'Formatierungsmethode. (Nur bei root_key)',
                comment: 'Kommentar',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
                discardedAt: 'Deaktiviert am',
            },
        },
        wirecard_requests: {
            name: 'Wirecard Vorgang |||| Wirecard Vorgänge',
            fields: {
                authorizationStatus: 'Status des Authentifizierungsvorgangs',
                type: 'Typ',
                requestId: 'Vorgangsnummer',
                response: 'Antwort von Wirecard',
                responseStatus: 'Status von Wirecard',
                status: 'Status',
                transactionId: 'Transaktion',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
                wirecardTransactionId: 'Transaktions ID von Wirecard',
            },
        },
        wpg_business_cases: {
            name: 'Wirecard Konfiguration |||| Wirecard Konfigurationen',
            fields: {
                name: 'Name',
                merchantAccountId: 'Händlerkonto ID',
                secretKey: 'Secret Key',
                paymentMethodId: 'Bezahlmethode',
                createdAt: 'Erstellt am',
                updatedAt: 'Aktualisiert am',
                discardedAt: 'Deaktiviert am',
            },
        },
    },
}

export const customMessages = {
    spgo: {
        restaurants: {
            reportType: {
                monthly: 'Monatlich',
                weekly: 'Wöchentlich',
                daily: 'Täglich',
            },
        },
        actions: {
            reactivate: 'Aktivieren',
            discard: 'Deaktivieren',
        },
        paymentMethodName: {
            credit_card_non3d: 'Kreditkarte(o. 3D)',
            apple_pay: 'Apple Pay',
            google_pay: 'Google Pay',
            pay_pal: 'PayPal',
            sofort: 'Klarna',
            credit_card: 'Kreditkarte',
            alipay: 'Alipay',
            wechat: 'WeChat',
            paydirekt: 'paydirekt',
            voucher_card: 'Gutschein',
        },
        printerReports: {
            empty: 'Alle',
        },
        dashboard: {
            unauthorized: 'Bitte melden sie sich an',
            dropdown: {
                day: 'Tag',
                week: 'Woche',
                month: 'Monat',
                total: 'Gesamt',
            },
            card: {
                numberTransactions: 'Anzahl Transaktionen',
                numberSuccess: 'Anzahl erfolgreicher Transaktionen',
                transactionVolume: 'Transaktionsvolumen',
                averageVolume: '\u2205 Transaktionsvolumen',
                subtitle: '<span class="%{leftClass}">Offline</span> | <span class="%{rightClass}">Online</span> Payment',
            },
            weekDash: {
                week: 'KW',
                title: 'Transaktionsvolumen in €',
                online: 'Online',
                offline: 'Offline',
                mon: 'Mo',
                tue: 'Di',
                wed: 'Mi',
                thu: 'Do',
                fri: 'Fr',
                sat: 'Sa',
                sun: 'So',
            },
            graphDash: {
                piePayment: 'Erfolgreiche Transaktionen nach Zahlart',
                outerInnerMessage: 'Außen: offline | Innen: online',
                upperLowerMessage: 'Oben: offline | Unten: online',
                pieUser: {
                    title: 'Übersicht der Einfach-/Mehrfachnutzer',
                    single: 'Einfach',
                    multiple: 'Mehrfach',
                },
                barStatus: {
                    title: 'Transaktionen je Zahlart nach Status',
                    init: 'initialisiert',
                    cancel: 'abgebrochen',
                    failed: 'fehlgeschlagen',
                    success: 'erfolgreich',
                },
                barVolume: {
                    title: 'Transaktionsvolumen nach Zahlart',
                    amount: 'Betrag',
                    tip: 'Trinkgeld',
                },
                pieTip: '\u2205 Trinkgeld',
                barTip: 'Trinkgeld nach Zahlart',

            },
            titles: {
                comparison: 'Wochenvergleich',
                payData: 'Zahlungsdaten / Trinkgelddaten',
            },
        },
        load: 'Laden',
        error: 'Fehler',
        refreshTitle: 'Stand: ',
    },
}
