import React from 'react'
import {
    Create,
    Datagrid,
    DateField,
    DeleteButton,
    Edit,
    EditButton,
    Filter,
    ReferenceField,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin'
import List from '../components/List'
import ReferenceInput from '../components/ReferenceInput'
import { AccountBalance } from '@material-ui/icons'

export const PackageFeesIcon = AccountBalance

const PackageFeesFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="paymentMethodId" reference="payment_methods" alwaysOn allowEmpty>
            <SelectInput optionText="name"/>
        </ReferenceInput>
    </Filter>
)

export const PackageFeesList = (props) => (
    <List {...props} sort={{ field: 'paymentMethodId', order: 'DESC' }} filters={<PackageFeesFilter/>}>
        <Datagrid>
            <TextField source="id"/>
            <ReferenceField source="paymentMethodId" reference="payment_methods" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="packageId" reference="packages" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
            <ShowButton/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
)

export const PackageFeesShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <ReferenceField source="paymentMethodId" reference="payment_methods" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="packageId" reference="packages" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="shared"/>
            <TextField source="fixed"/>
            <TextField source="buyRateFixed"/>
            <TextField source="sellRateFixed"/>
            <TextField source="buyRateShared"/>
            <TextField source="sellRateShared"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
)

const PackageFeesTitle = ({ record }) => {
    return <span>PackageFee {record ? `"${record.name}"` : ''}</span>
}

export const PackageFeesEdit = (props) => (
    <Edit title={<PackageFeesTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <ReferenceInput source="paymentMethodId" reference="payment_methods" validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput source="packageId" reference="packages" validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="buyRateFixed" validate={[required()]}/>
            <TextInput source="sellRateFixed" validate={[required()]}/>
            <TextInput source="buyRateShared" validate={[required()]}/>
            <TextInput source="sellRateShared" validate={[required()]}/>
        </SimpleForm>
    </Edit>
)

export const PackageFeesCreate = (props) => (
    <Create title="Create a PackageFee" {...props}>
        <SimpleForm>
            <ReferenceInput source="paymentMethodId" reference="payment_methods" validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput source="packageId" reference="packages" validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="buyRateFixed" validate={[required()]}/>
            <TextInput source="sellRateFixed" validate={[required()]}/>
            <TextInput source="buyRateShared" validate={[required()]}/>
            <TextInput source="sellRateShared" validate={[required()]}/>
        </SimpleForm>
    </Create>
)
